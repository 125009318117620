// Typ statusu
// Słownik
// lista wyboru
// VO: ApplicationStatus

// 1 => 'Potwierdzone przez wnioskującego',
// 2 => 'Odrzucone przez akceptującego',
// 3 => 'Zaakceptowane - czekające na realizację',
// 4 => 'Przedawnione',
// 5 => 'W trakcie realizacji',
// 6 => 'Odrzucone przez bank',
// 7 => 'Zrealizowane',
// 8 => 'Zaakceptowane przez akceptującego wyższego rzędu',
// 9 => 'Odrzucone przez akceptującego wyższego rzędu',
// 10 => 'Odrzucone przez wnioskującego',
// 11 => 'Odrzucone przez dział operacji finansowych',
// 12 => 'Odrzucone przez administratora',
// 13 => 'Zrealizowane ręcznie',

const typeOfApplicationStatus = [
  {
    index: 1,
    name: "Potwierdzone przez wnioskującego",
    value: "Potwierdzone przez wnioskującego",
    text: "Potwierdzone przez wnioskującego",
    color: "info",
  },
  {
    index: 2,
    name: "Odrzucone przez akceptującego",
    value: "Odrzucone przez akceptującego",
    text: "Odrzucone przez akceptującego",
    color: "danger",
  },
  {
    index: 3,
    name: "Zaakceptowane - czekające na realizację",
    value: "Zaakceptowane - czekające na realizację",
    text: "Zaakceptowane - czekające na realizację",
    color: "warning",
  },
  {
    index: 4,
    name: "Przedawnione",
    value: "Przedawnione",
    text: "Przedawnione",
    color: "danger",
  },
  {
    index: 5,
    name: "W trakcie realizacji",
    value: "W trakcie realizacji",
    text: "W trakcie realizacji",
    color: "dark",
  },
  {
    index: 6,
    name: "Odrzucone przez bank",
    value: "Odrzucone przez bank",
    text: "Odrzucone przez bank",
    color: "danger",
  },
  {
    index: 7,
    name: "Zrealizowane",
    value: "Zrealizowane",
    text: "Zrealizowane",
    color: "success",
  },
  {
    index: 8,
    name: "Zaakceptowane przez akceptującego wyższego rzędu",
    value: "Zaakceptowane przez akceptującego wyższego rzędu",
    text: "Zaakceptowane przez akceptującego wyższego rzędu",
    color: "warning",
  },
  {
    index: 9,
    name: "Odrzucone przez akceptującego wyższego rzędu",
    value: "Odrzucone przez akceptującego wyższego rzędu",
    text: "Odrzucone przez akceptującego wyższego rzędu",
    color: "danger",
  },
  {
    index: 10,
    name: "Odrzucone przez wnioskującego",
    value: "Odrzucone przez wnioskującego",
    text: "Odrzucone przez wnioskującego",
    color: "danger",
  },
  {
    index: 11,
    name: "Odrzucone przez dział operacji finansowych",
    value: "Odrzucone przez dział operacji finansowych",
    text: "Odrzucone przez dział operacji finansowych",
    color: "danger",
  },
  {
    index: 12,
    name: "Odrzucone przez administratora",
    value: "Odrzucone przez administratora",
    text: "Odrzucone przez administratora",
    color: "danger",
  },
  {
    index: 13,
    name: "Zrealizowane ręcznie",
    value: "Zrealizowane ręcznie",
    text: "Zrealizowane ręcznie",
    color: "success",
  },
];

const typeOfApplications = [
  {
    index: 1,
    id: 1,
    name: "Przelew krajowy",
    value: "Przelew krajowy",
    text: "Przelew krajowy",
    color: "info",
    icon: "mdi mdi-cash-100",
  },
  {
    index: 2,
    id: 2,
    name: "Przelew zagraniczny",
    value: "Przelew zagraniczny",
    text: "Przelew zagraniczny",
    color: "info",
    icon: "mdi mdi-currency-eur",
  },
  {
    index: 3,
    id: 3,
    name: "Przelew skarbowy",
    value: "Przelew skarbowy",
    text: "Przelew skarbowy",
    color: "info",
    icon: "mdi mdi-bank-outline",
  },
  {
    index: 4,
    id: 4,
    name: "Zasilenie karty",
    value: "Zasilenie karty",
    text: "Zasilenie karty",
    color: "info",
    icon: "mdi mdi-transfer",
  },
];

export { typeOfApplicationStatus };
export { typeOfApplications };

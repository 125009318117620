const dataTypeOfBankFiles = [
  {
    id: 1,
    index: 1,
    name: "ELIXIR",
    value: "ELIXIR",
    text: "ELIXIR",
    description: "Elixir - przelewy krajowe, skarbowe, Split Payment",
  },
  {
    id: 2,
    index: 2,
    name: "XML",
    value: "XML",
    text: "XML",
    description: "Format uniwersalny - wszystekie typy przelewów zgodny z ISO",
  },
  {
    id: 3,
    index: 3,
    name: "XMLDB",
    value: "XMLDB",
    text: "XMLDB",
    description: "Format dla banku Deutche Bank",
  },
  {
    id: 4,
    index: 4,
    name: "PLA",
    value: "PLA",
    text: "PLA",
    description: "Format MT103 dla przelewów zagranicznych",
  },
  {
    id: 5,
    index: 5,
    name: "MTMS",
    value: "MTMS",
    text: "MTMS",
    description: "Format dla banku CITI Handlowy",
  },
  {
    id: 6,
    index: 6,
    name: "PLACA",
    value: "PLACA",
    text: "PLACA",
    description: "Format dla banku Credit Agricole dla przelewów zagranicznych",
  },
];

export {dataTypeOfBankFiles}

